<template lang="pug">
	b-modal#modalEditAccount(ref='modal' hide-footer hide-header @hide='onHide' size='sm' modal-class='modal-dialog-height-100' :centered='!isMobile')
		span.btn-close(aria-label="Close" @click="close")
			i.fontello.fontello-icon-cross

		h2.modal-xs-title.mb-4 {{ $t('finance.editRequsitesH') }}

		p.mb-4.lh-18(v-html="description2")

		b-btn.w-100(variant='primary' @click='close') {{ $t('social.understand') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SERVICE_STRING } from '@/utils/string';
import FormAddEmail from '@/components/Form/Email';
import { bus } from '@/main.js';
import { PAY_SYSTEM } from '@/models/account';
import { ENV } from '@/api/index';

export default {
    name: 'ModalEditAccount',
    components: {
        FormAddEmail,
    },
    props: {
        onlyOrganization: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        type: null,
        requisite: null,
        organizationType: null,
        subTypes: [PAY_SYSTEM[1], PAY_SYSTEM[2]],
        editedItem: null,
    }),
    mounted() {},
    computed: {
        ...mapGetters({
            isPending: 'finance/isPending',
            item: 'finance/wizardEditAccountOpened',
        }),

        description() {
            return this.$t('finance.addCardTinkoffDesc').replace(
                '%holdDuration%',
                this.me.holdDuration + ' ' + SERVICE_STRING.ru_ends(this.me.holdDuration, this.$t('finance.days'))
            );
        },
        description2() {
            return this.$t('finance.editRequsitesText').replace(
                '%link%',
                'https://t.me/' + this.$t('contacts.support_tg')
            );
        },
    },
    methods: {
        ...mapActions({
            edit: 'finance/editAccount',
            getRequisite: 'finance/accountRequisite',
        }),

        close() {
            this.$refs.modal.hide();
            this.requisite = false;
        },

        onHide() {
            this.$store.commit('finance/setWizardEditAccountOpened', false);
            this.organizationType = null;
        },

        validateEmail() {
            return bus.$emit('validateFormEmail');
        },

        emailAdded(email) {
            this.edit({ id: this.item.id, emailForReceipt: email })
                .then(v => {
                    this.close();
                })
                .catch(v => {
                    this.$notify('error', v);
                });
        },

        async action() {
            if (this.item.isTinkoff()) return this.validateEmail();

            let r = { response: true };
            await bus.$emit('validateFormAccount', r);

            if (r.response) {
                new Promise((res, rej) => {
                    if (this.requisite && this.requisite.id) return bus.$emit('sendFormEditAccount', { res, rej });

                    bus.$emit('sendFormAddRequisite', this.item, res, rej);
                });
            }
        },
    },

    watch: {
        item(v) {
            if (v && v != null && v.id) {
                this.requisite = v.requisite;
                this.editedItem = v.requisite;

                this.organizationType = v.requisite ? v.requisite.type : this.subTypes[0];
                this.$refs.modal.show();
            }
        },

        organizationType(n, o) {
            if (o != null && n != o && this.requisite && this.requisite.id) {
                this.requisite = { id: this.requisite.id };
            }
        },
    },
};
</script>

<style scoped lang="scss"></style>
