<template lang="pug">
	.withdraw-crypto(:class="{'step-3' : step == 3}")
		.d-flex.flex-column(v-if="step == 1")
			p.b3.mb-4.disclaimer.warning {{ $t('finance.withdrawal.minSumCripto') }}
			.btn.btn-primary.mt-auto(@click='showWithdrawalInput') {{$t('social.understand')}}
		.d-flex.flex-column(v-if="step == 4")
			p.b3.mb-4.disclaimer.warning(v-html='withdrawalSumDesc')
			p.b3.mb-4 {{ $t('finance.cardHasWithdrawalDesc2') }}
			.btn.btn-primary.mt-auto(@click='close') {{$t('social.understand')}}

		.d-flex.flex-column(v-if="step == 2")
			my-input.mb-4(
				v-model="form.sum"
				:type="'number'"
				:label="$t('forms.sum')"
				:icon="'icon-money'"
				:errorFeedback="errorTextSum"
				@blur="validSum"
			)
			.btn.btn-primary.mt-auto(@click='nextStep' :class="{'disabled': isPending || errorTextSum}") {{$t('wizard.continue')}}

		.d-flex.flex-column(v-if="step == 3")
			p.b3.mb-2 {{ $t('finance.withdraw.checkDetails') }}
			.d-flex.align-items-center.withdraw-crypto__item
				span.b3.text-grey.col-md-5.p-0.mb-2.mb-md-0 {{ $t('finance.addressTRC') }}
				span.col-md-7.p-0 {{ card.usdtAddress }}
			.d-flex.align-items-center.withdraw-crypto__item
				span.b3.text-grey.col-md-5.p-0.mb-2.mb-md-0 {{ $t('forms.sum') }}
				span.col-md-7.p-0 {{ `${form.sum} USDT` }}
			p.b3.text-grey.border-rounded.my-3.withdraw-crypto__converted(v-html="$t('finance.withdraw.dollarsConverted')")
			b-form-checkbox.b3.mb-3(id="confirm-amount" v-model="form.isConfirmAmount" name="confirm-amount" :value="true" :unchecked-value="false") {{ $t('finance.withdraw.confirmAmount') }}
			b-form-checkbox.b3.mb-4(id="address-correct" v-model="form.isAddressCorrect" name="address-correct" :value="true" :unchecked-value="false") {{ $t('finance.withdraw.addressCorrect') }}
			.btn.btn-primary.w-100.mt-auto(@click='save' :class="{'disabled': isPending || form.isConfirmAmount == false || form.isAddressCorrect == false}") {{$t('wizard.continue')}}
</template>

<script>
import MyInput from '@/components/UI/MyInput';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'WithdrawCrypto',
    components: {
        MyInput,
    },
    data: () => ({
        step: 1,
        errorTextSum: '',
        form: {
            sum: null,
            isConfirmAmount: false,
            isAddressCorrect: false,
        },
        addressTRC: 'TPTdeTG8mZo9sgmeefn8y4395xjYoftxwJ',
    }),
    created() {
        this.setDefault();
    },
    methods: {
        ...mapActions({
            add: 'finance/addWithdrawal',
        }),
        close() {
            this.$bvModal.hide(`withdraw-${this.card.id}`);
            this.$emit(`close`);
        },
        save() {
            let params = { id: this.card.id };

            if (this.canSelectAmountForWithdrawal) params.payout = Number(this.form.sum * 100).toFixed(0);

            this.add(params)
                .then(c => {
                    this.success = true;
                    this.card.madeWithdrawal(Number(params.payout), c);
                    this.close();
                    this.$emit('openWithdrawCreated');
                })
                .catch(c => {
                    this.$notify('error', '', c);
                });
        },
        validSum() {
            this.errorTextSum = '';

            if (this.form.sum == 0 || this.form.sum == null || this.form.sum.trim() == '')
                return (this.errorTextSum = this.$t('promotions.form.error'));
            if (this.form.sum.match(/^\d*(\.\d{1,2})?$/) == null)
                return (this.errorTextSum = this.$t('errors.numeric.amount'));
            if (
                this.form.sum < this.card.minSubForWithdrawal() ||
                Number(this.form.sum) > Number(this.card.available.price())
            )
                return (this.errorTextSum = this.$t('finance.withdrawal.minSumCriptoError').replace(
                    '%sum%',
                    this.card.minSubForWithdrawal() + ' ' + this.card.available.currencyTitle()
                ));
        },

        nextStep() {
            this.step = 3;
        },
        setDefault() {
            if (this.card != null && this.card.available) {
                this.form.sum = this.card.available.price();
                return (this.step = this.card.canWithdrawal()
                    ? this.me.isPartner
                        ? 3
                        : 2
                    : this.card.hasWithdrawal()
                      ? 4
                      : 1);
            }

            this.step = 1;
        },
        showWithdrawalInput() {
            if (this.card == null) return this.$emit(`close`);
            if (
                this.form.sum < this.card.minSubForWithdrawal() ||
                Number(this.form.sum) > Number(this.card.available.price())
            ) {
                return this.$bvModal.hide(`withdraw-${this.card.id}`);
            }

            this.step = 2;
        },
    },
    computed: {
        ...mapGetters({
            isPending: 'finance/isPending',
            card: 'modals/withdrawalStripe',
        }),
        withdrawalSumDesc() {
            if (!this.card || !this.card.hasWithdrawal()) return '';

            if (this.card.requestedWithdrawals[0] && this.card.requestedWithdrawals[0].price)
                return this.$t('finance.cardHasWithdrawalDesc').replace(
                    '%sum%',
                    `<span class='font-weight-medium'>${this.card.requestedWithdrawals[0].price.priceWithCurrency()}</span>`
                );

            return this.$t('finance.cardHasWithdrawalDesc').replace('%sum%', '');
        },

        minWithdrawalTitle() {
            return this.$t('finance.cardCantWithdrawalDesc').replace(
                '%sum%',
                this.card.minSubForWithdrawal() + ' ' + this.card.available.currencyTitle()
            );
        },

        canSelectAmountForWithdrawal() {
            return (
                this.me.isMerchant &&
                this.card &&
                (this.card.isOrganization() || (this.card.isStripe() && this.card.canWithdrawal()))
            );
        },
    },
    watch: {
        card: {
            handler(nV, oV) {
                if (nV != null) {
                    this.setDefault();
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.withdraw-crypto {
    width: 437px;

    &.step-3 {
        width: 511px;

        @include respond-below(sm) {
            width: 100%;
        }
    }

    @include respond-below(sm) {
        width: 100%;
    }

    &__item {
        padding: 12px 0;
        border-bottom: 1px solid var(--border-line);

        @include respond-below(sm) {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    &__converted {
        padding: 20px;
        background-color: var(--bg-back);

        @include respond-below(sm) {
            padding: 15px;
        }
    }
}
</style>
