<template lang="pug">
	.d-inline-block
		b-btn.text-nowrap.btn-tiny.btn-long(v-if='!isMobile' variant='outline-primary' @click='click' size='sm') {{ $t('forms.reset') }}
		i.text-primary.cursor-pointer.fontello.fontello-icon-reset-filters.display-7(v-else @click='click()')
</template>
<script>
export default {
    name: 'PwBtnReset',
    methods: {
        click(i) {
            this.$emit('onClick', i);
        },
    },
};
</script>
