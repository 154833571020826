<template lang="pug">
	pw-dropdown(:model='model' :list='list' :placeholder="$t('h1.project')" titleProperty='title' @onChange='selected' @onShow='getList')
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PwDropdown from '@/components/Form/PwDropdown';

export default {
    name: 'PwDropdownProjects',
    components: {
        'pw-dropdown': PwDropdown,
    },
    props: {
        model: {
            type: Object,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: { value: null, title: null },
        },
    },
    methods: {
        ...mapActions({
            get: 'project/shortList',
        }),
        selected(i) {
            this.$emit('onChange', i);
        },
        getList() {
            if (this.isGetDataOnce) return;

            this.get().then(v => (this.isGetDataOnce = true));
        },
    },
    data: () => ({
        isGetDataOnce: false,
    }),
    computed: {
        ...mapGetters({
            items: 'project/itemsShort',
        }),
        list() {
            let all = { value: null, title: this.$t('forms.all') };
            if (!this.items) return [all];

            return [all, ...this.items];
        },
    },
};
</script>
