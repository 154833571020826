<template lang="pug">
	.d-flex.flex-column.card-basic.account-transfer(v-if="isShow")
		icon-close.news__close(@click="isShow = false")
		.account-transfer__content
			p.b1.mb-3(v-html="$t('finance.accountTransfer.title')")
			p.b3.text-grey.mb-3 {{ $t('finance.accountTransfer.text') }}
			.btn.btn-primary.btn-small.account-transfer__button(@click="$bvModal.show('modal-support')") {{ $t('h1.support') }}
</template>

<script>
import IconClose from '@/components/Common/IconClose';
import { socials } from '@/constants/config';

export default {
    name: 'AccountTransfer',
    components: {
        IconClose,
    },
    data: () => ({
        isShow: true,
    }),
    computed: {
        socials() {
            return socials.apply(this);
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.account-transfer {
    position: relative;
    width: calc(50% - 10px);
    min-height: 201px;
    margin-bottom: 20px;
    background-image: url('/assets/img/current/ia-bank.png');
    background-repeat: no-repeat;
    background-position: top -4px right -40px;
    background-size: 280px;
    overflow: hidden;

    @include respond-below(lg) {
        width: 100%;
        min-height: auto;
        background-position: top 80px right -20px;
        background-size: 160px;
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &__content {
        max-width: 271px;
    }

    &__button {
        width: 153px;
    }
}
</style>
