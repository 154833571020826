<template lang="pug">
	b-modal#modaladdbankcard.add-bank-card(ref='modal' centered content-class="modal-max-content" header-class="d-flex p-0 border-0 align-items-center mb-3" body-class="p-0 d-flex flex-column" hide-footer)
		template(#modal-header="{ close }")
			h3 {{ $t('button.addCard') }}
			icon-close.add-bank-card__close(@click="close()")

		.add-bank-card__content
			p.b3.mb-4 {{ description }}
			p.b4.text-grey.mb-4(v-if='isSafari' v-html="$t('forms.safariPopupNotification')")

			.btn.btn-primary.w-100.mt-auto(@click='listen(item)' :class="{'disabled': isPending}") {{$t('wizard.continue')}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DEVICE } from '@/utils/device';
import { STORAGE } from '@/api/storage';
import { SERVICE_STRING } from '@/utils/string';
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'ModalAddBankCard',
    components: {
        IconClose,
    },
    props: {
        item: {
            type: Object,
            default: () => null,
        },
    },
    data: () => ({
        isSafari: DEVICE.isSafari(),
        error: null,
        isFirefox: DEVICE.isFirefox(),
        alreadyShownAlert: false,
    }),
    computed: {
        ...mapGetters({
            project: 'project/opened',
            isPending: 'finance/isPending',
            activeCards: 'finance/activeCardsUniq',
        }),
        description() {
            return this.$t('finance.addCardTinkoffDesc').replace(
                '%holdDuration%',
                this.me.holdDuration + ' ' + SERVICE_STRING.ru_ends(this.me.holdDuration, this.$t('finance.days'))
            );
        },
    },
    methods: {
        ...mapActions({
            add: 'finance/addCard',
            getAccount: 'finance/accountId',
            getActiveCards: 'finance/activeCards',
        }),
        close() {
            this.$refs.modal.hide();
        },
        async listen(item) {
            this.error = null;
            this.alreadyShownAlert = false;
            STORAGE.setCard(false);
            STORAGE.setRedirectUrl({ name: 'none' });

            let wo;

            if (this.isSafari || this.isFirefox) wo = window.open();

            try {
                let res = await this.add({ accountId: item.id });

                if (res && res.error) {
                    this.$emit('error');
                    return this.$notify('error', res.error);
                }

                if (!res || res == null || !res.paymentURL || res.paymentURL == null) {
                    this.$emit('error');
                    return (this.error = this.$t('finance.errorAddCardNull'));
                }

                if (this.isSafari || this.isFirefox) {
                    wo.location = res.paymentURL;
                    this.close();
                    this.$emit('waitingCard');
                } else {
                    window.open(res.paymentURL, '_blank').focus();
                    this.close();
                    this.$emit('waitingCard');
                }

                this.timer = setInterval(() => {
                    if (STORAGE.hasCardError()) {
                        clearInterval(this.timer);
                        this.$emit('errorCard');
                    }

                    if (STORAGE.isCardAdded()) {
                        clearInterval(this.timer);

                        this.timer = setInterval(() => {
                            this.getAccount(item.id)
                                .then(v => {
                                    if (v && v.card && v.card.pan) {
                                        clearInterval(this.timer);
                                        STORAGE.setCard(false);
                                        this.getActiveCards({ id: v.card.id }).then(list => {
                                            let card = list && list.length > 0 ? list[0] : {};
                                            if (!this.alreadyShownAlert)
                                                this.$notify('success', this.$t('success.cardAdded'));
                                            this.alreadyShownAlert = true;
                                            item.setCard(card);
                                            this.$emit('success', item);
                                            this.close();
                                        });
                                    }
                                })
                                .catch(v => {
                                    this.$notify('error', v);
                                    this.$emit('error', v);
                                    this.error = v;
                                });
                        }, 2000);

                        return;
                    }

                    if (STORAGE.hasCardError()) {
                        this.error = STORAGE.getCardError();
                        this.$emit('error');
                    }
                }, 1000);
            } catch (v) {
                this.$notify('error', v);
                this.$emit('error', v);
                this.error = v;
            }
        },
    },
    watch: {
        item: {
            handler(nV, oV) {
                if (nV != null) {
                    return this.$refs.modal.show();
                }

                if (this.$refs.modal) {
                    this.$refs.modal.hide();
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.add-bank-card {
    &__content {
        width: 437px;

        @include respond-below(sm) {
            width: 100%;
        }
    }

    &__close {
        transition: var(--animation-time-short);

        &:hover,
        &:active {
            opacity: 0.7;
        }
    }
}
</style>
