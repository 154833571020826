<template lang="pug">
	b-alert.bank-card(show variant='primary')
		.d-flex.justify-content-between.align-items-center(v-if='item && item != null')
			.bank-card--logo
				img(:src='`/assets/img/icons/${item.systemType}.svg`')
			.bank-card--desc.flex-grow-1
				span.mb-1.d-block {{ item.pan | cardNumberLong }}
				span.text-muted(v-if='item.accounts.length > 0') {{ $t('finance.cardStatusUsed') }}
				span.text-muted(v-else) {{ $t('finance.cardDoesntUsed') }}
			.bank-card--action
				i.text-primary.fontello.cursor-pointer.fontello-icon-trash(@click='openModalRemove(item)')
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'BankAccount',
    components: {},
    props: {
        item: {
            default: null,
            type: Object,
        },
    },
    data: () => ({}),
    computed: {
        statusRu() {
            if (!this.item) return '';

            return this.item.status;
        },
    },
    created() {},
    methods: {
        ...mapActions({
            checkForRemove: 'finance/removeCard',
        }),

        openModalRemove() {
            if (this.item.isTinkoff()) {
                this.item.canRemove = true;
                this.$emit('openModalRemove', this.item);
                return;
            }

            this.checkForRemove({ id: this.item.id, is_possible: true })
                .then(can => {
                    this.item.canRemove = can;
                    this.errors = null;
                    this.$emit('openModalRemove', this.item);
                })
                .catch(v => {
                    this.item.canRemove = false;
                    // this.errors = v;
                    this.$emit('openModalRemove', this.item);
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.bank-card {
    .d-flex {
        gap: 8px;
    }
}
.bank-card--logo {
    width: 80px;
    img {
        width: auto;
        max-width: 65px;
        height: 25px;
    }
}

@media (max-width: 767.98px) {
    .bank-card {
        .d-flex {
            gap: 16px;
        }
    }
    .bank-card--logo {
        width: 65px;
        text-align: center;
    }
}
</style>
