<template lang="pug">
	pw-dropdown(:model='model' :list='list' :placeholder="$t('finance.bankAccount')" @onChange='selected' :preTitle="$t('finance.bankAccount')")
</template>
<script>
import { mapGetters } from 'vuex';
import PwDropdown from '@/components/Form/PwDropdown';

export default {
    name: 'PwDropdownAccounts',
    components: {
        'pw-dropdown': PwDropdown,
    },
    props: {
        model: {
            type: Object,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: { value: null, title: null },
        },
    },
    methods: {
        selected(i) {
            this.$emit('onChange', i);
        },
    },
    computed: {
        ...mapGetters({
            items: 'finance/accounts',
        }),
        list() {
            let all = { value: null, id: this.$t('forms.all') };
            if (!this.items) return [all];

            return [all, ...this.items];
        },
    },
};
</script>
