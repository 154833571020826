<template lang="pug">
	i.text-primary.cursor-pointer.fontello.fontello-icon-filter.display-7(v-if='isMobile' @click='click()' :class='{"btn-sm-w-badge": selected > 0}')
</template>
<script>
export default {
    name: 'PwBtnMobileFilters',
    props: {
        selected: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        click() {
            this.$emit('onOpen', null);
        },
    },
};
</script>
<style lang="scss" scoped>
.btn-sm-w-badge {
    position: relative;

    &:after {
        content: '';
        background: var(--error-color);
        border-radius: 50%;
        width: 8px;
        height: 8px;

        position: absolute;
        top: 3px;
        right: -3px;
    }
    padding-bottom: 4px;
}
</style>
