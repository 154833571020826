<template lang="pug">
	b-dropdown.dropdown-custom(:text="model.value == null ? placeholder : title" variant='outline-primary' menu-class='w-100' no-flip @show='getList' size='sm' ref='dropdown')
		b-dropdown-item(@click='select(i)' v-for='i in list' :key='i.id') {{ i[titleProperty] }}
</template>
<script>
export default {
    name: 'PwDropdown',
    props: {
        model: {
            type: Object,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: { value: null, title: null },
        },
        list: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: null,
        },
        preTitle: {
            type: String,
            default: null,
        },
        titleProperty: {
            type: String,
            default: 'id',
        },
    },
    methods: {
        select(i) {
            this.$emit('onChange', i);
        },
        getList() {
            this.$emit('onShow');
        },
        onShow() {
            let btnSize = this.$el.querySelector('.dropdown-toggle').clientWidth;
            let dropSize = this.$el.querySelector('.dropdown-menu').clientWidth;
            if (btnSize > dropSize) this.$el.querySelector('.dropdown-menu').style.width = btnSize + 1 + 'px';
            else if (btnSize < dropSize) this.$el.querySelector('.dropdown-toggle').style.width = dropSize + 2 + 'px';
        },
        onScroll() {
            this.$refs.dropdown.hide(true);
        },
    },

    computed: {
        title() {
            return this.preTitle ? this.preTitle + ':' + this.model.title : this.model.title;
        },
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    },

    watch: {
        list(n, v) {
            return;
        },
    },
};
</script>
