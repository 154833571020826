<template lang="pug">
	b-modal#modalerroraddbankcard.erroraddbankcard(ref='modal' :centered="!isMobile" :scrollable="isMobile" content-class="modal-max-content" header-class="d-flex p-0 border-0 align-items-center mb-3" body-class="p-0 d-flex flex-column" hide-footer)
		template(#modal-header)
			h3 {{ $t('finance.errorAddCard') }}
			icon-close.erroraddbankcard__close(@click="close()")

		p.b3.text-grey.mb-1 {{ $t('finance.errorCode') }}:
			span.b3.text-main.ml-1 {{ code }}
		p.b3.text-grey.mb-3 {{ $t('finance.errorReason') }}:
			span.b3.text-main.ml-1 {{ reason }}

		p.b3.mb-4.disclaimer.warning {{ $t('finance.errorYourSelf') }}

		a.d-block.text-decoration-none.w-100.mt-auto(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
			.btn.btn-border(@click='close') {{ $t('pages.contactSupports') }}
</template>

<script>
import { STORAGE } from '@/api/storage';
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'ModalErrorAddBankCard',
    components: {
        IconClose,
    },
    props: {
        reason: {
            type: String,
            default: '',
        },
        code: {
            type: [String, Number],
            default: '',
        },
    },
    mounted() {
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (modalId == 'modalerroraddbankcard') {
                STORAGE.setCardError();
            }
        });
    },
    methods: {
        close() {
            this.$refs.modal.hide();
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.erroraddbankcard {
    &__close {
        transition: var(--animation-time-short);

        &:hover,
        &:active {
            opacity: 0.7;
        }
    }
}

#modalerroraddbankcard {
    .modal-dialog {
        width: 472px;

        @include respond-below(sm) {
            width: 100%;
        }
    }
}
</style>
