<template lang="pug">
	b-modal#modalWaitingCard.waitingAddCard(ref='modal' :centered="!isMobile" :scrollable="isMobile" content-class="modal-max-content" header-class="d-flex p-0 border-0 align-items-center mb-3" body-class="p-0 d-flex flex-column" hide-footer)
		template(#modal-header)
			h3 {{ $t('finance.waitingAddCardTitle') }}
			icon-close.waitingAddCard__close(@click="close()")

		p.b3.waitingAddCard__text {{ $t('finance.waitingAddCard') }}

		.btn.btn-primary.w-100.mt-auto(@click='close()') {{ $t('social.understand') }}
</template>

<script>
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'ModalWaitingCard',
    components: {
        IconClose,
    },
    mounted() {
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (modalId == 'modalWaitingCard') {
                this.close();
            }
        });
    },
    methods: {
        close() {
            this.$refs.modal.hide();
            this.$router.push({ name: 'finance' });
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.waitingAddCard {
    &__text {
        margin-bottom: 25px;
    }

    &__close {
        transition: var(--animation-time-short);

        &:hover,
        &:active {
            opacity: 0.7;
        }
    }
}

#modalWaitingCard {
    .modal-dialog {
        width: 472px;

        @include respond-below(sm) {
            width: 100%;
        }
    }
}
</style>
