import { render, staticRenderFns } from "./ModalWithdrawalSuccess.vue?vue&type=template&id=3de0cb97&scoped=true&lang=pug"
import script from "./ModalWithdrawalSuccess.vue?vue&type=script&lang=js"
export * from "./ModalWithdrawalSuccess.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3de0cb97",
  null
  
)

export default component.exports