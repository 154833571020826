<template lang="pug">
	b-card(no-body)
		b-card-body
			.d-flex.justify-content-between.align-items-start
				h3.mb-2 {{ me.fio }}
				//- i.text-primary.cursor-pointer.fontello.fontello-icon-edit(@click='edit')
			table
				tbody
					tr
						td.text-muted.text-nowrap.py-1.pr-4.td-first {{ $t('social.addAccount') }}:
						td.w-100 {{ me.id }} 
					tr
						td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.tgId') }}:
						td.w-100 {{ me.tgId }} 
					tr
						td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.tgUsername') }}:
						td.w-100
							a(:href='`https://t.me/${me.tgUsername}`' target='_blank') {{ me.tgUsername }} 
					tr
						td.text-muted.text-nowrap.py-1.pr-4 {{ $t('user.register') }}:
						td.w-100 {{ me.registeredAt | dateDDMMMMYYYY }} 
					tr
						td.text-muted.text-nowrap.py-1.pr-4 {{ $t('h1.email') }}:
						td.w-100.text-break {{ me.emailPersonal }} 
					tr
						td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.phone') }}:
						td.w-100.text-break {{ me.phone }} 
					tr
						td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.placeholder.country') }}:
						td.w-100
							span.text-break(v-if='me.country == "RUS"') {{ $t('finance.accountTypes[0].items[0].country2') }}
							span.text-break(v-else) {{ country }}
			div(v-if='ooo')
				hr.primary
				h3.mb-2(v-if='ooo.type.value == "INDIVIDUAL"') {{ $t('forms.placeholder.organization') }}
				h3.mb-2(v-else) {{ $t('forms.placeholder.organization') }}
				table
					tbody
						tr
							td.text-muted.text-nowrap.py-1.pr-4.td-first {{ $t('forms.placeholder.fullName') }}:
							td.w-100
								span.text-break {{ ooo.fullName }}
						
						tr
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.placeholder.companyShortSuper') }}:
							td.w-100
								span {{ ooo.shortName }}

						tr
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.placeholder.inn') }}:
							td.w-100
								span {{ ooo.inn }}
						
						tr(v-if='ooo.kpp')
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.placeholder.kpp') }}:
							td.w-100
								span {{ ooo.kpp }}
						
						tr(v-else)
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.placeholder.ogrnip') }}:
							td.w-100
								span {{ ooo.ogrn }}

						tr
							td.text-muted.text-nowrap.py-1.pr-4(v-if='ooo.type.value == "INDIVIDUAL"') {{ $t('forms.placeholder.ownerAddress') }}:
							td.text-muted.text-nowrap.py-1.pr-4(v-else) {{ $t('forms.placeholder.address') }}:
							td.w-100
								span.text-break {{ ooo.addressShown ? ooo.addressShown : (ooo.addressesStreet ? ooo.addressesStreet : ooo.adress) }}
						
						tr
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.city') }}:
							td.w-100
								span.text-break {{ ooo.addressesCity }}
						tr
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.placeholder.zip') }}:
							td.w-100
								span.text-break {{ ooo.addressesZip }}
						tr
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.email') }}:
							td.w-100
								span.text-break {{ ooo.email }}
						
				hr.primary
				h3.mb-2 {{ $t(`finance.accountTypes[1].requisitesOwner`) }}
				table
					tbody
						tr
							td.text-muted.text-nowrap.py-1.pr-4.td-first {{ $t('forms.placeholder.fio') }}:
							td.w-100
								span.text-break {{ ooo.lastName }} {{ ooo.firstName }} {{ ooo.middleName }}
						
						tr
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.placeholder.birthday') }}:
							td.w-100
								span.text-break {{ ooo.birthDate | dateDDMMMMYYYY }}
						tr
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.phone') }}:
							td.w-100
								span.text-break {{ ooo.phone }}
				
				hr.primary
				h3.mb-2 {{ $t(`finance.accountTypes[1].requisitesCompany`) }}
				table
					tbody
						tr
							td.text-muted.text-nowrap.py-1.pr-4.td-first {{ $t('forms.placeholder.bank') }}:
							td.w-100
								span.text-break {{ ooo.bankAccountName }}
						
						tr
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.placeholder.bik') }}:
							td.w-100
								span.text-break {{ ooo.bankAccountBik }}
						tr
							td.text-muted.text-nowrap.py-1.pr-4 {{ $t('forms.accountNumber2') }}:
							td.w-100
								span.text-break {{ ooo.bankAccountNumber }}
										
		//- b-card-footer.card-footer-primary.lh-20(v-if='me.needMoveToOOO()')
			.d-flex.justify-content-between.align-items-center
				span.mr-4 {{ $t('project.paymentAccounts.move') }}
				b-btn(variant='primary' @click='move') {{ $t('project.paymentAccounts.moveOn') }}
		
		b-card-footer.card-footer-primary.lh-20(v-if='ooo')
			span(v-html='changeRequisiteTitle')
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isoMethods } from '@/constants/countries';

export default {
    name: 'Personal',
    components: {},
    props: {},
    data: () => ({}),
    computed: {
        ...mapGetters({
            accountList: 'finance/accounts',
        }),
        country() {
            if (!this.me) return '';
            return isoMethods.getName(this.me.country);
        },
        ooo() {
            let i = _.find(this.accountList, a => {
                if ((a.isOOO() || a.isIP()) && a.hasRequisite()) return a;
            });

            return i ? i.shop : false;
        },

        changeRequisiteTitle() {
            return this.$t('project.paymentAccounts.changeRequisites').replace(
                /\$\{bus\.botSupport\}/,
                this.supportBoth
            );
        },
    },
    created() {},
    methods: {
        ...mapActions({}),
        edit() {
            this.$router.push({ query: { editProfile: true } });
        },
        move() {
            this.$router.push({ query: { editAccount: true } });
        },
    },
};
</script>
<style lang="scss" scoped>
.td-first {
    min-width: 185px;
}

@media (max-width: 767.98px) {
}
</style>
