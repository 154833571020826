<template lang="pug">
	.reports
		.d-flex.justify-content-between.align-items-center
			span.b1(v-if="isMobile") {{ $t('finance.withdrawal.reportsTitle') }}
			dropdown-general.reports__dropdown(:list="years" :selecteDefault="year.value.toString().slice(-1)" @onChange="changeYear" :icon="'icon-calendar'")

		.d-flex.flex-column.mt-2(v-if='list.length > 0')
			.d-flex.flex-column(v-for='g in groupedList')
				span.b1.mb-2.mt-2 {{ g[0].dateBy | dateMMMMYYYY | capitalize }}
				.mb-2(v-for='i in g' :key='i.id')
					span.b3.cursor-pointer.text-primary(@click='download({id: i.id, fileName: i.fileName})') {{ i.fileName }}
		h3.mb-4.mt-5.text-center(v-else) {{ $t('finance.withdrawal.noReports') }}

</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment-timezone';
import { foundationYear } from '@/constants/config';
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral';

export default {
    name: 'Reports',
    components: {
        DropdownGeneral,
    },
    data: () => {
        let yyyy = new Date().getFullYear();

        return {
            list: [],
            year: { value: yyyy, title: yyyy },
            years: _.map(_.fill(new Array(yyyy - foundationYear + 1), true), (v, i) => {
                return { value: foundationYear + i, title: foundationYear + i, id: foundationYear + i };
            }),
            filters: {
                'periodStart[after]': moment(yyyy + '-01-01').format('YYYY-MM-DDT00:00:00'),
                'periodEnd[before]': moment(yyyy + '-12-31').format('YYYY-MM-DDT23:59:59'),
                tz: moment.tz.guess(),
            },
        };
    },
    computed: {
        groupedList() {
            return _.groupBy(_.sortBy(this.list, 'dateBy').reverse(), 'dateBy');
        },
    },
    methods: {
        ...mapActions({
            reports: 'finance/reports',
            download: 'finance/reportsDownload',
        }),
        onShow() {
            if (this.list.length == 0) this.reports(this.filters).then(v => (this.list = v));
        },
        changeYear(i) {
            this.year.value = i;

            this.filters['periodStart[after]'] = moment(this.year.value + '-01-01').format('YYYY-MM-DDT00:00:00');
            this.filters['periodEnd[before]'] = moment(this.year.value + '-12-31').format('YYYY-MM-DDT23:59:59');

            this.reports(this.filters).then(v => (this.list = v));
        },
    },
    created() {
        this.onShow();
    },
    watch: {
        year() {
            this.changeYear(this.year);
        },
    },
};
</script>

<style lang="scss">
.reports {
    &__dropdown {
        button {
            width: 127px;
        }

        .dropdown-menu {
            min-width: 127px;
        }
    }
}
</style>
