<template lang="pug">
	b-modal#modalinactiveshop.inactive-shop(ref='modal' centered content-class="modal-max-content" header-class="d-flex p-0 border-0 align-items-center mb-3" body-class="p-0 d-flex flex-column" hide-footer @hide='hide')
		template(#modal-header="{ close }")
			h3 {{ $t('errors.required.data') }}
			icon-close.inactive-shop__close(@click="close()")

		//.d-flex.flex-column.inactive-shop__content(v-if='item')
		.d-flex.flex-column.inactive-shop__content
			p.b3.mb-3 {{ $t('finance.inactiveShopDesc') }}

			.mb-3(v-if='item && item.shop && (!item.shop.addressesStreet || !item.shop.addressesZip)')
				my-input-dropdown.inactive-shop__dropdown(
					v-model="address"
					:list="addressList"
					:icon="'icon-house'"
					:label='item.isIP() ? $t("forms.placeholder.ownerAddress") : $t("forms.placeholder.companyAddress")'
					:isError='errorAddress'
					@input='searchAddress'
					@select='afterSelectAddress'
					@onClear='afterSelectAddress'
				)

				.text-danger.b4.mt-1(v-if='!$v.address.required && alreadyAttemptSend')
					span(v-if='item.isIP() || item.isStripe()') {{ $t('errors.required.ownerAddress') }}
					span(v-else) {{ $t('errors.required.companyAddress') }}
				.text-danger.b4.mt-1(v-if='$v.address.required && !$v.address.zipInAddress && alreadyAttemptSend') {{ $t('errors.required.zipInAddress') }}
			.mb-3(v-if='item && item.shop && !item.shop.middleName')
				my-input(
					v-model.trim="$v.middleName.$model"
					:label="$t('forms.placeholder.middleNameOwner')"
					:errorFeedback="''"
					:icon="'icon-profile'"
				)
				.text-danger.mt-2(v-if='!$v.middleName.required && alreadyAttemptSend') {{ $t('errors.required.middleName') }}
				.text-danger.mt-2(v-else-if='!$v.middleName.kiril && alreadyAttemptSend') {{ $t('errors.valid.kirilOnly') }}
				.text-danger.mb-2(v-else-if='!$v.middleName.maxLength && alreadyAttemptSend') {{ $t('errors.maxLength.middleName') }}

			.d-flex.flex-column(v-if='item && item.shop && !item.shop.birthDate')
				p.b3.text-grey.mb-3 {{ $t('forms.placeholder.birthdayOOO') }}
				pw-select-birthday(@success='changedBirthday' @failed='cleanBirthday')
				.text-danger.b4.mt-1.mb-2(v-if='!$v.birthDate.required && alreadyAttemptSend') {{ $t('errors.required.birthday') }}

		.btn.btn-primary.mt-4(@click="validateForm") {{$t('wizard.continue')}}
</template>

<script>
import '@/custom_npm/vue-select/dist/vue-select.css';
import { mapActions, mapGetters } from 'vuex';
import { locale } from '@/main';
import { en, ru } from 'vuejs-datepicker/dist/locale';
import vSelect from '@/custom_npm/vue-select/src';
import moment from 'moment-timezone';
import PwSelectBirthday from '@/components/Form/PwSelectBirthday.vue';
import MyInputDropdown from '@/components/UI/MyInputDropdown';
import MyInput from '@/components/UI/MyInput';
import IconClose from '@/components/Common/IconClose';

const { required, minLength, maxLength } = require('vuelidate/lib/validators');
const kiril = value => {
    if (value == null) return false;
    if (_.some(value.split('-'), v => v == '')) return false;
    if (_.some(value.split('—'), v => v == '')) return false;

    return /^([а-яА-ЯёЁ\-\—\s\.])+$/.test(value);
};
const zipInAddress = value => {
    return /\d{6}/.test(value);
};

export default {
    name: 'ModalInActiveShop',
    components: {
        vSelect,
        PwSelectBirthday,
        IconClose,
        MyInputDropdown,
        MyInput,
    },
    data: () => ({
        isDisabled: false,
        middleName: null,
        birthDate: null,
        address: null,
        fullAddress: null,
        locale: locale,

        l: { en: en, ru: ru },
        alreadyAttemptSend: false,
        openDate: new Date(2000, 10, 10),
        disabledDates: {
            from: new Date(new Date().getFullYear() - 18, new Date().getMonth() - 1, new Date().getDate()),
        },
        addressList: [],
    }),
    created() {
        this.searchAddress = _.debounce(query => {
            if (query && query.length < 3) return;

            this.getAddress({ query: query }).then(vv => {
                this.addressList = vv;
            });
        }, 400);
    },
    methods: {
        ...mapActions({
            editShop: 'finance/editShop',
            getAddress: 'finance/findAddress',
        }),
        close() {
            this.$refs.modal.hide();
        },
        hide() {
            this.alreadyAttemptSend = false;
        },
        changedBirthday(date) {
            this.birthDate = date;
        },
        cleanBirthday() {
            this.birthDate = null;
        },
        save() {
            let p = {
                id: this.item.shop.id,
            };

            if (this.middleName) p.middleName = this.middleName;
            if (this.fullAddress) {
                p.addressesStreet = _.compact([
                    this.fullAddress.data.street_with_type,
                    this.fullAddress.data.house_type
                        ? this.fullAddress.data.house_type + ' ' + this.fullAddress.data.house
                        : null,
                    this.fullAddress.data.flat_type
                        ? this.fullAddress.data.flat_type + ' ' + this.fullAddress.data.flat
                        : null,
                ]).join(', ');
                p.addressesCity = _.uniq(
                    _.compact([
                        this.fullAddress.data.region_with_type,
                        this.fullAddress.data.area_with_type,
                        this.fullAddress.data.city_with_type,
                        this.fullAddress.data.settlement_with_type,
                    ])
                ).join(', ');
                p.addressesZip = this.fullAddress.data.postal_code;
                p.address = this.address;
            }
            if (this.birthDate) {
                p.birthDate = moment(
                    new Date(Date.UTC(this.birthDate.year, this.birthDate.month - 1, this.birthDate.day))
                ).format('YYYY-MM-DD');
            }

            this.editShop(p).then(v => {
                this.item.setShop(v);
                this.close();
                this.$store.commit('modals/setWithdrawal', this.item);
            });
        },
        setDefault() {
            this.middleName = null;
            this.birthDate = null;
            this.address = null;
            this.error = null;
        },
        afterSelectAddress(v) {
            this.address = v;
            this.fullAddress = _.find(this.addressList, a => a.unrestricted_value == v);
        },
        validateForm() {
            this.$v.$touch();
            this.alreadyAttemptSend = true;

            if (this.$v.$anyError) return false;

            return this.save();
        },
        openModalWithdrawal() {
            this.$store.commit('modals/setWithdrawal', null);
            this.$nextTick(() => {
                this.$store.commit('modals/setWithdrawal', this.item);
            });
        },
    },
    computed: {
        ...mapGetters({
            item: 'modals/inactiveShop',
        }),
        errorAddress() {
            return this.alreadyAttemptSend && (!this.$v.address.required || !this.$v.address.zipInAddress);
        },
    },
    validations() {
        let v = {
            middleName: { required, kiril, maxLength: maxLength(50) },
            birthDate: { required },
            address: { required, zipInAddress },
        };

        if (this.item && this.item.shop && this.item.shop.middleName) delete v.middleName;
        if (this.item && this.item.shop && this.item.shop.birthDate) delete v.birthDate;
        if (this.item && this.item.shop && this.item.shop.addressesStreet && this.item.shop.addressesZip)
            delete v.address;

        return v;
    },
    watch: {
        item: {
            handler(nV, oV) {
                if (nV != null) {
                    this.setDefault();
                    return this.$refs.modal.show();
                }

                if (this.$refs.modal) this.close();
            },
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.inactive-shop {
    &__content {
        width: 511px;

        @include respond-below(sm) {
            width: 100%;
        }
    }

    &__dropdown {
        .animated {
            .dropdown-menu {
                &.show {
                    max-height: 295px !important;
                }
            }
        }
    }

    &__close {
        transition: var(--animation-time-short);

        &:hover,
        &:active {
            opacity: 0.7;
        }
    }
}
</style>
