<template lang="pug">
	.comment-tooltip
		p.b3.m-0(v-html='item.comment')

		//- div(v-if='account && account.shop')
			.d-block.mb-2
				span.b3.text-grey {{ $t('forms.placeholder.accountNumber') }}:
				span.b3.ml-1(v-html="account.shop.bankAccountNumber")
			.d-block.mb-2
				span.b3.text-grey {{ $t('forms.placeholder.currency') }}:
				span.b3.ml-1(v-html="item.price.currency")
			.d-block.mb-2
				span.b3.text-grey {{ $t('promotions.form.name') }}:
				span.b3.ml-1(v-html="account.shop.fullName")
			.d-block.mb-2
				span.b3.text-grey {{ $t('forms.placeholder.inn') }}:
				span.b3.ml-1(v-html="account.shop.inn")
			.d-block.mb-2
				span.b3.text-grey {{ $t('forms.placeholder.bik') }}:
				span.b3.ml-1(v-html="account.shop.bankAccountBik")
			.d-block.mb-2
				span.b3.text-grey {{ $t('forms.placeholder.corCheck') }}:
				span.b3.ml-1(v-html="account.shop.bankCorrAccountNumber")
		//- div(v-else-if='account && account.usdtAddress')
			.d-block.mb-2
				span.b3.text-grey {{ $t('finance.usdtTitle') }}:
				span.b3.ml-1(v-html="account.usdtAddress")
			.d-block.mb-2
				span.b3.text-grey {{ $t('forms.placeholder.currency') }}:
				span.b3.ml-1(v-html="item.price.currency")
		//- div(v-else-if='account && account.card')
			.d-block.mb-2
				span.b3.text-grey {{ $t('forms.placeholder.accountNumber') }}:
				span.b3.ml-1(v-html="account.card.pan")
			.d-block.mb-2
				span.b3.text-grey {{ $t('forms.placeholder.currency') }}:
				span.b3.ml-1(v-html="item.price.currency")
		//- div(v-else)
			.d-block.mb-2
				span.b3.text-grey {{ $t('forms.placeholder.accountNumber') }}:
				span.b3.ml-1(v-html="item.purse")
		p.b3.text-grey.my-2 {{ $t('h1.support') }}
		a.d-block.text-decoration-none(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
			pw-btn.comment-tooltip__button(:text="$t('h1.writeTelegram')" :icon="'icon-telegram'")
</template>

<script>
import PwBtn from '@/components/Buttons/PwBtn';

export default {
    name: 'CommentTooltip',
    components: {
        PwBtn,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        receipt() {
            return this.item && this.item.receipts && this.item.receipts.length ? this.item.receipts[0] : {};
        },
        account() {
            if (!this.item) return {};

            return _.find(this.me.accounts, a => a.id == this.item.account.id);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.comment-tooltip {
    width: 270px;

    @include respond-below(sm) {
        width: 100%;
    }

    &__button {
        width: 253px;

        @include respond-below(sm) {
            width: 100%;
        }
    }
}
</style>
