<template lang="pug">
	.d-flex.finance-filter
		.d-flex.flex-column.pr-md-4.finance-filter__tabs(v-if="step == 1 || !isMobile")
			span.b3.btn.btn-text.finance-filter__reset-all(v-if="isMobile" @click="resetAll" :class="{'disabled': !used}") {{ $t('button.resetAll') }}

			pw-btn.finance-filter__tab(
				v-for="(tab, i) in projects.length > 0 ? financeIncomeFiltersTabs : financeWithdrawFiltersTabs"
				:key="i"
				v-if="tab.title"
				:class="{'active-tab': tabFilters == i && !isMobile}"
				:text="tab.title"
				:icon="tab.icon"
				:quantity="tab.quantity"
				@click.native="tabFilters = i, step = 2, disabledAllTab"
			)

			.d-flex.position-relative.mb-auto.date-picker-control(:class="{'is-float': incomeDate.start}")
				v-date-picker.w-100.date-picker(v-model="incomeDate" :key="incomeDateKey" :min-date="new Date(me.registeredAt)" :max-date="new Date()" :masks="masks" is-range :locale="currentLanguage" :popover="{ visibility: 'click' }")
					template(v-slot="{ inputValue, inputEvents, togglePopover }")
						span.icon-calendar(@click="togglePopover()")
						.d-flex.date-picker__input(@click="togglePopover()")
							input.b3.date-picker__input-calendar.date-picker__input-calendar--first(:class="{'visually-hidden': !incomeDate.start && !incomeDate.end}" id="income-date-calendar" :value="inputValue.start" v-on="inputEvents.start" readonly)
							span.b3.mr-1(v-if="incomeDate.start && incomeDate.end") -
							span.b3.date-picker__input-calendar(v-if="incomeDate.start && incomeDate.end") {{ inputValue.end }}
							input.visually-hidden(:value="inputValue.end" v-on="inputEvents.end")
				label.m-0.b3.date-picker__label(:class="{'b4': incomeDate.start && incomeDate.end}" for="income-date-calendar") {{ $t('plan.periodSimple') }}
				icon-close.date-picker__reset(v-if="incomeDate.start" @click="incomeDate.start = null, incomeDate.end = null, incomeDateKey++")

			.d-flex.w-100.mt-auto.mt-md-4
				.btn.btn-medium.btn-primary.w-100.mr-md-2.finance-filter__btn(@click="applyFilter" :disabled='isloading')
					.loader(v-if="isloading && !isloadingReset")
					span(v-else='') {{ $t('paymentPage.apply') }}
				.btn.btn-medium.btn-border.w-100.text-nowrap(v-if="!isMobile" @click="resetAll" :class="{'disabled': !used}")
					.loader.primary(v-if="isloadingReset")
					span(v-else='') {{ $t('button.resetAll') }}

		.d-flex.flex-column.pl-md-4.w-100.finance-filter__tabs(v-if="step == 2 || !isMobile")
			.d-flex.justify-content-between.align-items-center.mb-3.finance-filter__header
				svg.cursor-pointer.mr-3(v-if="isMobile" @click="step = 1" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg")
					path(d="M15 19.9181L8.47997 13.3981C7.70997 12.6281 7.70997 11.3681 8.47997 10.5981L15 4.07812" stroke="#8292A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
				h3.h3.text-nowrap(v-if="isMobile") {{ projects.length > 0 ? financeIncomeFiltersTabs[tabFilters].title : financeWithdrawFiltersTabs[tabFilters].title }}
				.d-flex.flex-column.w-100
					icon-close.mb-2(v-if="!isMobile && tabFilters == 0 && accountList.length >= 5" @click="$emit('click', $event)")
					icon-close.mb-2(v-if="!isMobile && tabFilters == 1 && projects.length >= 5" @click="$emit('click', $event)")
					// В my-input-search условие всегда нужны только не отфильтрованные массивы
					my-input-search.mb-3(v-if="tabFilters == 0 && accountList.length > 5 && !isMobile" v-model="searchAccountList" :placeholder="$t('dashboards.search')")
					my-input-search.mb-3(v-if="tabFilters == 1 && projects.length > 5 && !isMobile" v-model="searchProject" :placeholder="$t('dashboards.search')")
					.d-flex.justify-content-end.justify-content-md-between.align-items-center(v-if="(tabFilters == 0 && searchedAccountList.length != 0) || (tabFilters == 1 && searchedProjects.length != 0) || tabFilters == 2")
						span.b3.finance-filter__choose-all(v-if="tabFilters == 0" :class="{'active-choose-all': accountListCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="accountListCross")
						icon-close(v-if="!isMobile && tabFilters == 0 && accountList.length < 5" @click="$emit('click', $event)")
						span.b3.finance-filter__choose-all(v-if="tabFilters == 1" :class="{'active-choose-all': projectsCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="projectsCross")
						icon-close(v-if="!isMobile && tabFilters == 1 && projects.length < 5" @click="$emit('click', $event)")
						span.b3.finance-filter__choose-all(v-if="tabFilters == 2" :class="{'active-choose-all': statusCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="statusCross")
						icon-close(v-if="!isMobile && tabFilters == 2" @click="$emit('click', $event)")
			b-form-checkbox.b3.position-relative.finance-filter__tab-checkbox.finance-filter__tab-checkbox--status(
				v-if="tabFilters == 2"
				v-for="(status, i) in statuses"
				:key="status.id"
				:class="status.id.toLowerCase()"
				v-model="statusValue"
				:value="status.id"
				@change="disabledAllTab"
			)
				span.position-absolute
				| {{ status.title }}

			my-input-search.mb-1(v-if="tabFilters == 0 && accountList.length > 5 && isMobile" v-model="searchAccountList" :placeholder="$t('dashboards.search')")
			b-form-checkbox.b3.finance-filter__tab-checkbox(v-if="tabFilters == 0" v-model="accountListValue" v-for="(account, i) in searchedAccountList" :key="i" :value="account.id" @change="disabledAllTab") {{ account.id }}

			my-input-search.mb-1(v-if="tabFilters == 1 && projects.length > 5 && isMobile" v-model="searchProject" :placeholder="$t('dashboards.search')")
			.finance-filter__tab-checkbox-wrap
				.d-flex.cursor-pointer.mr-md-2(v-if="tabFilters == 1" v-for="(item, i) in searchedProjects" :key="item.id")
					b-form-checkbox.b3.w-100.finance-filter__tab-checkbox(v-model="projectsValue" :value="item.id" @change="disabledAllTab" :id="item.id")
						.avatar-rect.avatar-rect-sm.mr-2.mb-auto
							img.avatar-rect-img(v-if='item.image && !item.isNeuro()' :src='item.image.contentUrl' :alt='item.title')
							img.avatar-rect-img(v-else-if="item && item.isNeuro()" :src="item.botImage ? item.botImage.contentUrl : '/assets/img/neurobots/neurobot.webp'" alt='Neurobot')
						.d-flex.flex-column
							span.b1.overflow-wrap.mr-1 {{ item.title ? item.title : $t('instruction.step0.neurobot.newNeurobot') }}
							b-badge.mt-1.mr-auto(v-if="item.isInActive()" variant='light' pill) {{ $t('project.notActive') }}
							b-badge.mt-1.mr-auto(v-else-if="item.hasSomeResourcesBotIssue()" variant='warning' pill) {{ $t('bot.resourceAccessIssueShort') }}
							b-badge.mt-1.mr-auto(v-else-if="item && item.isArchived()" variant='light' pill) {{ $t('project.archived') }}
							b-badge.mt-1.mr-auto(v-else-if="me && item && item.hasMoreThanOnePlanWithoutResource()" variant='warning' pill) {{ $t('project.finishSettings') }}
							b-badge.mt-1.mr-auto(v-else-if="me && item && item.isBlocked()" variant='danger' pill) {{ $t('forms.blocked') }}

			.d-flex.flex-column.m-auto(v-if="(tabFilters == 0 && searchedAccountList.length == 0) || (tabFilters == 1 && searchedProjects.length == 0)")
				icon-close.finance-filter__close(v-if="!isMobile" @click="$emit('click', $event)")
				h1.mb-3.text-center 😢
				span.b4.text-grey {{ $t('errors.emptySearch') }}

			.d.flex.mt-auto
				.btn.btn-primary.w-100.mt-3.mt-md-0.finance-filter__btn(v-if="isMobile" @click="step = 1")
					.loader(v-if="isloading")
					span(v-else='') {{ $t('paymentPage.apply') }}
</template>

<script>
import PwBtn from '@/components/Buttons/PwBtn';
import { mapActions, mapGetters } from 'vuex';
import IconClose from '@/components/Common/IconClose';
import { STORAGE } from '@/api/storage';
import MyInputSearch from '@/components/UI/MyInputSearch';
import ProjectView from '@/components/Common/ProjectView';
import { STATUS as STATUS_INCOME } from '@/models/payment';
import { STATUS as STATUS_WITHDRAWALS } from '@/models/withdraw';

export default {
    name: 'FinanceFilters',
    components: {
        PwBtn,
        IconClose,
        MyInputSearch,
        ProjectView,
    },
    props: {
        projectsList: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        isloading: false,
        isloadingReset: false,
        tabFilters: 0,
        quantity: null,
        disabledResetTab: true,
        indexStatus: null,
        step: 1,
        currentLanguage: STORAGE.getLang(),
        masks: {
            input: 'DD.MM.YYYY',
            weekdays: 'WW',
        },
        searchAccountList: '',
        searchProject: '',
        statusValue: [],
        accountListValue: [],
        projects: [],
        projectsValue: [],
        variantBadge: '',
        variantText: '',
        incomeDateKey: 0,
        incomeDate: {
            start: null,
            end: null,
        },
        statusCross: false,
        accountListCross: false,
        projectsCross: false,
    }),
    created() {
        this.projects = this.projectsList;
        this.isloading = false;
        this.setValues();
    },
    computed: {
        ...mapGetters({
            accountListActive: 'finance/accounts',
            accountListActiveRemoved: 'finance/accountsActiveRemoved',
            previousData: 'modals/financeFilters',
        }),
        accountList() {
            return _.map(
                this.accountListActiveRemoved.length == 0 ? this.accountListActive : this.accountListActiveRemoved,
                a => {
                    return { id: a.id };
                }
            );
        },
        financeIncomeFiltersTabs() {
            return [
                {
                    title: this.$t('forms.placeholder.check'),
                    icon: 'fontello-icon-wallet-money',
                    quantity: this.accountListValue.length,
                },
                { title: this.$t('h1.project'), icon: 'fontello-icon-plans', quantity: this.projectsValue.length },
                { title: this.$t('project.status'), icon: 'fontello-icon-status', quantity: this.statusValue.length },
            ];
        },
        financeWithdrawFiltersTabs() {
            return [
                {
                    title: this.$t('forms.placeholder.check'),
                    icon: 'fontello-icon-wallet-money',
                    quantity: this.accountListValue.length,
                },
                {},
                { title: this.$t('project.status'), icon: 'fontello-icon-status', quantity: this.statusValue.length },
            ];
        },
        used() {
            return !(
                this.statusValue.length == 0 &&
                this.accountListValue.length == 0 &&
                this.projectsValue.length == 0 &&
                this.incomeDate.start == null
            );
        },
        searchedAccountList() {
            return this.searchAccountList.length > 0
                ? _.filter(this.accountList, p => p.title.toLowerCase().includes(this.searchAccountList.toLowerCase()))
                : this.accountList;
        },
        searchedProjects() {
            return this.searchProject.length > 0
                ? _.filter(this.projects, p => p.title.toLowerCase().includes(this.searchProject.toLowerCase()))
                : this.projects;
        },
        statuses() {
            let list =
                this.type == 'income'
                    ? _.cloneDeep(_.slice(STATUS_INCOME, 2, 4))
                    : _.cloneDeep(_.slice(STATUS_WITHDRAWALS, 0, 3));

            return _.map(list, l => {
                return {
                    id: l.id,
                    title: this.isLocaleRu ? l.titleRU : l.titleEN,
                };
            });
        },
    },
    mounted() {
        if (this.previousData && this.previousData.income.incomeDateStore) {
            this.incomeDate = structuredClone(this.previousData.income.incomeDateStore);
        }
    },
    methods: {
        async applyFilter() {
            this.$emit('onApply', {
                status: this.statusValue,
                accountList: this.accountListValue,
                projects: this.projectsValue,
                incomeDate: this.incomeDate,
                used: this.used,
            });
            this.$store.commit('modals/financeFilters', {
                statusValue: this.statusValue,
                accountListValue: this.accountListValue,
                projectsValue: this.projectsValue,
                incomeDateStore: structuredClone(this.incomeDate),
                type: this.type,
            });
            this.isloading = true;
        },
        resetAll() {
            this.isloadingReset = true;
            this.statusValue = [];
            this.accountListValue = [];
            this.projectsValue = [];
            (this.incomeDate = {
                start: null,
                end: null,
            }),
                this.incomeDateKey++,
                this.disabledAllTab();
            this.statusCross = false;
            this.accountListCross = false;
            this.projectsCross = false;
            this.applyFilter();
        },
        resetAllTab() {
            if (this.tabFilters == 0) {
                this.statusValue = [];
                this.statusCross = false;
            } else if (this.tabFilters == 1) {
                this.accountListValue = [];
                this.accountListCross = false;
            } else if (this.tabFilters == 2) {
                this.projectsValue = [];
                this.projectsCross = false;
            }
            this.disabledAllTab();
        },
        chooseAllTab() {
            if (this.tabFilters == 2) {
                if (this.statusValue.length != this.statuses.length) {
                    this.statusValue = this.statuses.map(x => x.id);
                    this.statusCross = true;
                } else {
                    this.statusValue = [];
                    this.statusCross = false;
                }
            } else if (this.tabFilters == 0) {
                if (this.accountListValue.length != this.accountList.length) {
                    this.accountListValue = this.accountList.map(x => x.id);
                    this.accountListCross = true;
                } else {
                    this.accountListValue = [];
                    this.accountListCross = false;
                }
            } else if (this.tabFilters == 1) {
                if (this.projectsValue.length != this.projects.length) {
                    this.projectsValue = this.projects.map(x => x.id);
                    this.projectsCross = true;
                } else {
                    this.projectsValue = [];
                    this.projectsCross = false;
                }
            }
            this.disabledAllTab();
            this.applyFilter();
        },
        disabledAllTab() {
            if (this.tabFilters == 0 && this.statusValue == 0) return (this.disabledResetTab = true);
            if (this.tabFilters == 1 && this.accountListValue.length == 0) return (this.disabledResetTab = true);
            if (this.tabFilters == 2 && this.projectsValue.length == 0) return (this.disabledResetTab = true);

            this.disabledResetTab = false;

            if (this.tabFilters == 2) {
                if (this.statusValue.length == this.statuses.length) {
                    this.statusCross = true;
                } else {
                    this.statusCross = false;
                }
            } else if (this.tabFilters == 0) {
                if (this.accountListValue.length == this.accountList.length) {
                    this.accountListCross = true;
                } else {
                    this.accountListCross = false;
                }
            } else if (this.tabFilters == 1) {
                if (this.projectsValue.length == this.projects.length) {
                    this.projectsCross = true;
                } else {
                    this.projectsCross = false;
                }
            }
        },
        setValues() {
            _.each(this.previousData[this.type], (v, i) => {
                this[i] = v;
            });
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.finance-filter {
    @include respond-below(sm) {
        flex-grow: 1;
        padding-bottom: 20px;
    }

    &__reset-all {
        position: absolute;
        top: -42px;
        right: 16px;
    }

    &__tabs {
        &:first-child {
            width: 282px;
            min-width: 282px;
            border-right: 1px solid var(--grayscale-border);

            @include respond-below(sm) {
                width: 100%;
                min-width: 100%;
                border-right: none;
            }
        }
    }

    &__header {
        @include respond-below(sm) {
            position: absolute;
            top: -44px;
            left: 16px;
            right: 16px;
            background-color: var(--foreground-color);
        }
    }

    &__tab {
        margin-bottom: 15px;
    }

    &__choose-all {
        height: 32px;
        padding: 4px 15px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        border-radius: 20px;
        vertical-align: baseline;
        transition: var(--animation-time-short);
        border: 1px solid var(--grayscale-dark-line);
        cursor: pointer;

        i {
            font-size: 10px;
            margin-left: 5px;
        }

        &.active-choose-all {
            border: 1px solid transparent;
            background-color: var(--brand-bg-primary);
        }
    }

    .pw-btn {
        justify-content: start !important;
        padding: 10px 20px;
        background-color: var(--grayscale-bg-dark-back);

        &::before {
            padding: 7px;
            font-size: 18px;
            line-height: 18px;
            color: var(--brand-primary);
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: var(--foreground-color);
        }

        &:hover,
        &:active {
            svg {
                path,
                line {
                    stroke: var(--brand-primary) !important;
                }
            }
        }

        span {
            margin-left: 10px;
        }

        svg {
            margin-left: auto !important;

            path,
            line {
                stroke: var(--brand-primary) !important;
            }
        }

        &.active-tab {
            background-color: var(--brand-bg-primary);

            &:hover,
            &:active {
                background-color: var(--brand-bg-primary);
                box-shadow: none;

                .pw-btn__quantity {
                    background-color: var(--grayscale-dark-white-back);
                }
            }
        }
    }

    &__tab-checkbox-wrap {
        max-height: 280px;
        overflow-y: auto;

        @include respond-below(sm) {
            max-height: 100%;
            overflow-y: visible;
        }
    }

    &__tab-checkbox {
        padding: 0;
        min-height: 47px;

        .custom-control-label {
            height: 100%;
            padding: 15px 26px 15px 0;
            border-bottom: 1px solid var(--grayscale-border);

            &::before {
                top: 50%;
                left: auto;
                right: 0;
                transform: translateY(-50%);
            }
        }

        .custom-control-input:checked ~ .custom-control-label {
            &::after {
                top: 50%;
                left: auto;
                right: 0;
                transform: translateY(-50%);
            }
        }

        &--status {
            .custom-control-label {
                padding: 15px 26px 15px 18px;

                span {
                    top: 50%;
                    left: 0;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    transform: translateY(-50%);
                }
            }

            &.paid,
            &.success {
                .custom-control-label {
                    color: var(--brand-success);

                    span {
                        background-color: var(--brand-success);
                    }
                }
            }

            &.refund,
            &.requested {
                .custom-control-label {
                    color: var(--brand-orange);

                    span {
                        background-color: var(--brand-orange);
                    }
                }
            }

            &.error {
                .custom-control-label {
                    color: var(--brand-error);

                    span {
                        background-color: var(--brand-error);
                    }
                }
            }
        }
    }

    .my-input-search__input {
        height: 45px;
        font-size: 14px !important;
        line-height: 22px !important;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__btn {
        @include respond-below(sm) {
            height: 50px !important;
            font-size: 15px !important;
            line-height: 24px !important;
        }
    }
}
</style>
